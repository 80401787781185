import { j as n } from "./jsx-runtime-B6kdoens.js";
import { u as p, S as y, c as r } from "./Select-49a62830.esm-D16GEKKJ.js";
import { _ as S } from "./index-28E3tTLh.js";
import * as b from "react";
import { forwardRef as j } from "react";
import "react-dom";
import { B as d } from "./Badge-C0bj4DFU.js";
import { I as m } from "./Icon-AQuIivEZ.js";
import { S as w } from "./Separator.component-BNi038DN.js";
import { S as h } from "./Stack-C1p7gIIp.js";
import { T as a } from "./Text-RuGJaJiA.js";
import { T as o } from "./Tooltip-Dy7rqQaX.js";
import { U as u } from "./UserNameText.component-BfZNl7CQ.js";
import { c as x } from "./utils-CJ9afRe1.js";
var v = /* @__PURE__ */ j(function(e, t) {
  var s = p(e);
  return /* @__PURE__ */ b.createElement(y, S({
    ref: t
  }, s));
}), M = v;
const V = (e) => /* @__PURE__ */ n.jsx(r.DropdownIndicator, { ...e, children: /* @__PURE__ */ n.jsx(m, { name: "NavArrowDown", strokeWidth: 1.5, width: 20, height: 20 }) }), C = (e) => /* @__PURE__ */ n.jsxs(r.Option, { ...e, children: [
  e.data.avatar,
  /* @__PURE__ */ n.jsx(
    u,
    {
      size: "sm",
      truncate: !0,
      user: e.data.user,
      isMe: e.data.isMe,
      highlight: e.selectProps.inputValue
    }
  )
] }), O = (e) => /* @__PURE__ */ n.jsx(r.MultiValueRemove, { ...e, children: /* @__PURE__ */ n.jsx(m, { name: "Cancel", strokeWidth: 1.5, width: 20, height: 20 }) }), N = (e) => /* @__PURE__ */ n.jsx(r.GroupHeading, { ...e, children: e.children }), z = (e) => {
  if (!e.data.onLoan) {
    const t = e.options.filter((s) => f(s.label, e.selectProps.inputValue)).length;
    return /* @__PURE__ */ n.jsxs("div", { className: "flex flex-col gap-4 pl-2", children: [
      /* @__PURE__ */ n.jsxs(h, { row: !0, space: "sm", children: [
        /* @__PURE__ */ n.jsx(d, { size: "md", circle: !0, variant: "secondary", children: t }),
        /* @__PURE__ */ n.jsx(
          a,
          {
            size: "sm",
            weight: "medium",
            as: "h1",
            children: e.data.label
          }
        )
      ] }),
      /* @__PURE__ */ n.jsx("div", { className: "flex flex-col gap-2 text-sm", children: e.children })
    ] });
  }
  return /* @__PURE__ */ n.jsx(r.Group, { ...e, children: e.children });
}, L = (e) => /* @__PURE__ */ n.jsxs(r.Menu, { ...e, children: [
  /* @__PURE__ */ n.jsx(a, { size: "sm", weight: "medium", className: "px-3 pt-3 pb-2", children: "Send to" }),
  /* @__PURE__ */ n.jsx(w, {}),
  e.children
] }), P = (e) => {
  const t = e.options.reduce((s, i) => "options" in i && i.onLoan ? s + i.options.filter((g) => f(g.label, e.selectProps.inputValue)).length : s, 0);
  return /* @__PURE__ */ n.jsxs(r.MenuList, { ...e, children: [
    t > 0 && /* @__PURE__ */ n.jsxs(h, { row: !0, space: "sm", children: [
      /* @__PURE__ */ n.jsx(d, { size: "md", circle: !0, variant: "secondary", children: t }),
      /* @__PURE__ */ n.jsx(
        a,
        {
          size: "sm",
          weight: "medium",
          as: "h1",
          children: "On Loan"
        }
      )
    ] }),
    e.children
  ] });
}, I = (e) => /* @__PURE__ */ n.jsx(r.MenuPortal, { ...e, "data-toast": "true", children: e.children }), k = (e) => /* @__PURE__ */ n.jsx(r.NoOptionsMessage, { ...e, children: /* @__PURE__ */ n.jsxs(h, { space: "sm", items: "center", justify: "center", className: "flex-1", children: [
  /* @__PURE__ */ n.jsx(m, { name: "BigExclamation", width: 64, height: 64, className: "text-black-10" }),
  /* @__PURE__ */ n.jsx(a, { className: "text-black-primary font-medium", size: "sm", children: "User not found" })
] }) }), R = (e) => /* @__PURE__ */ n.jsx(r.MultiValue, { ...e, children: /* @__PURE__ */ n.jsxs(o, { children: [
  /* @__PURE__ */ n.jsx(o.Trigger, { asChild: !0, children: /* @__PURE__ */ n.jsx(
    u,
    {
      size: "sm",
      truncate: !0,
      user: e.data.user,
      isMe: e.data.isMe,
      highlight: e.selectProps.inputValue
    }
  ) }),
  /* @__PURE__ */ n.jsx(
    o.Content,
    {
      align: "start",
      side: "bottom",
      alignOffset: 0,
      children: /* @__PURE__ */ n.jsx(
        u,
        {
          size: "sm",
          user: e.data.user,
          isMe: e.data.isMe,
          highlight: e.selectProps.inputValue
        }
      )
    }
  )
] }) }), T = (e) => {
  const t = e.getValue().length;
  return /* @__PURE__ */ n.jsxs(r.ValueContainer, { ...e, children: [
    e.children,
    t > 1 && /* @__PURE__ */ n.jsxs(d, { variant: "secondary", className: "py-0.5 h-5 group-focus-within/select:hidden mt-1 border border-gray-neutral-80", children: [
      "+",
      t - 1,
      " more"
    ] })
  ] });
}, l = {
  base: "border-0  rounded-none bg-white hover:cursor-text text-sm",
  focus: "",
  nonFocus: ""
}, D = "text-gray-500 pl-1 group-focus-within/select:py-0.5", E = "pl-1 group-focus-within/select:py-0.5 group-focus-within/select:flex", G = "p-1 gap-1 group-focus-within/select:pt-1 group-focus-within/select:flex-wrap", H = "leading-7 ml-1", W = "bg-gray-neutral-30 truncate items-center py-0.5 h-5 pl-2 rounded-xl pr-1 gap-2 group-focus-within/select:flex [&:not(:first-of-type)]:hidden  group-focus-within/select:first:mt-0 first:mt-1 first:flex", B = "leading-6 py-0.5 ", F = "hover:text-red-800 hover:border-red-300", U = "p-1 gap-1 items-start flex flex-row", _ = "hidden w-0 overflow-hidden", A = "w-0", $ = "p-1 hover:bg-gray-100 text-black-primary rounded-md cursor-pointer", q = "mt-2 border-gray-neutral-80 border bg-white rounded-lg z-dialog", J = "text-black-primary text-sm font-medium", c = {
  base: "text-sm aria-disabled:opacity-50 aria-disabled:cursor-default truncate overflow-hidden cursor-pointer rounded-md p-2 flex flex-row gap-2 items-center",
  focus: "bg-gray-neutral-30 active:bg-gray-200",
  selected: "text-gray-500"
}, K = "group/select", Q = "pl-2 flex flex-col gap-3 text-sm [&>div]:flex [&>div]:flex-col [&>div]:gap-2 [&>div:last-of-type]:pl-2", X = "p-3 flex flex-col gap-4 scrollbar-stable", Y = " h-60 max-h-full flex items-center justify-center", me = (e) => /* @__PURE__ */ n.jsx(o.Provider, { children: /* @__PURE__ */ n.jsx(
  M,
  {
    isMulti: !0,
    closeMenuOnSelect: !1,
    hideSelectedOptions: !1,
    unstyled: !0,
    styles: {
      container: (t) => ({
        ...t,
        width: "100%",
        overflow: "hidden"
      }),
      input: (t) => ({
        ...t,
        order: 99,
        "input:focus": {
          boxShadow: "none"
        }
      }),
      valueContainer: (t) => ({
        ...t,
        flexWrap: "nowrap"
      }),
      menu: (t) => ({
        ...t,
        zIndex: 1304
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (t) => ({
        ...t
      }),
      option: (t) => ({
        ...t,
        display: "flex",
        cursor: "pointer"
      }),
      menuPortal: (t) => ({
        ...t,
        zIndex: 1304,
        pointerEvents: "auto"
      }),
      control: (t) => ({
        ...t,
        transition: "none",
        borderWidth: 0,
        boxShadow: "none"
      }),
      noOptionsMessage: (t) => ({
        ...t,
        backgroundColor: "transparent"
      })
    },
    components: {
      Option: C,
      Menu: L,
      Group: z,
      GroupHeading: N,
      MenuList: P,
      DropdownIndicator: V,
      MultiValueRemove: O,
      ValueContainer: T,
      MenuPortal: I,
      MultiValue: R,
      NoOptionsMessage: k
    },
    classNames: {
      control: ({ isFocused: t }) => x(
        t ? l.focus : l.nonFocus,
        l.base
      ),
      placeholder: () => D,
      input: () => E,
      container: () => K,
      valueContainer: () => G,
      singleValue: () => H,
      multiValue: () => W,
      multiValueLabel: () => B,
      multiValueRemove: () => F,
      indicatorsContainer: () => U,
      clearIndicator: () => _,
      indicatorSeparator: () => A,
      dropdownIndicator: () => $,
      menu: () => q,
      groupHeading: () => J,
      group: () => Q,
      menuList: () => X,
      option: ({ isFocused: t, isSelected: s }) => x(
        t && c.focus,
        s && c.selected,
        c.base
      ),
      noOptionsMessage: () => Y
    },
    ...e,
    className: "z-dialog",
    menuPlacement: "auto",
    menuPosition: "fixed",
    menuPortalTarget: document.body,
    onChange: (t) => e.onChange(t),
    openMenuOnClick: !1,
    openMenuOnFocus: !1,
    menuShouldScrollIntoView: !1,
    isOptionDisabled: (t) => e.value.some((s) => s.value === t.value),
    tabSelectsValue: !1,
    filterOption: (t, s) => s ? f(t.label, s) : t.data.onLoan
  }
) }), f = (e, t) => e.toLowerCase().includes(t.toLowerCase());
export {
  me as default
};
